import { Controller } from "@hotwired/stimulus"
import animateScrollTo from "animated-scroll-to"
import { Modal } from "bootstrap"

export default class extends Controller {
  static targets = [
    "results",
    "formParticipants",
    "dateRangePicker",
    "languageSelect",
    "otherLanguageSelect",
    "OfferSelect",
    "searchBtn",
    "checkoutBtn",
    "cart",
    "totalPrice",
    "cartCounter",
    "chosenCustomerName",
    "chosenCustomerEmail",
    "chooseCustomer",
    "chosenCustomer",
    "dismissCustomerBtn",
    "schoolYesBtn",
    "schoolNoBtn",
    "schoolSelectContainer",
    "schoolSelectFields",
    "schoolSelect",
    "organizationName",
    "organizationNameContainer",
    "eighthGradeContainer",
    "eighthGradeFields",
    "eighthGradeYesBtn",
    "eighthGradeNoBtn",
    "eighthGrade",
    "saveDetailsBtn",
    "loginSignupForm",
    "nextButton"
  ]

  // Add listener to show info-modal on connect
  connect() {
    document.addEventListener("click", this._display_date_info_modal.bind(this))

    if (
      this.hasSchoolSelectTarget &&
      this.element.dataset.tourOrganizer === "true"
    ) {
      this.schoolYesBtnTarget.click()
    }

    if (
      this.hasEighthGradeTarget &&
      (this.element.dataset.tourOrganizer === "true" ||
        this.element.dataset.austrianSchool === "true")
    ) {
      this.eighthGradeYesBtnTarget.click()
    }
  }

  // Remover listener to show info-modal on disconnect
  disconnect() {
    document.removeEventListener(
      "click",
      this._display_date_info_modal.bind(this)
    )
  }

  // Internal method to show info-modal if wrong date gets selected
  _display_date_info_modal(event) {
    if (event.target.classList.contains("flatpickr-day")) {
      new Modal(document.getElementById("bookingDateInfoModal")).show()

      // Not sure why this line is needed?
      if (this.hasDateRangePickerTarget)
        this.dateRangePickerTarget._flatpickr.close()
    }
  }

  // Choose ticket type
  displayOfferselect(event) {
    const type = event.currentTarget.getAttribute("data-type")
    this.OfferSelectTargets.forEach((element) => {
      if (element.getAttribute("data-type") == type)
        element.classList.remove("d-none")
      else element.classList.add("d-none")
    })
  }

  // Request an alternative language
  showRequestLanguage(event) {
    // Enable search butto
    this.searchBtnTarget.removeAttribute("disabled")
    event.target.style.border = ""

    // Return if real language was selected
    if (event.target.value) return

    // For regular customers, just show info modal
    if (!this.hasOtherLanguageSelectTarget) {
      new Modal(document.getElementById("languageInfoModal")).show()
      this.searchBtnTarget.setAttribute("disabled", "disabled")
      event.target.style.border = "1px solid red"
      return
    }

    // Visibility
    this.languageSelectTarget.classList.add("d-none")
    this.otherLanguageSelectTarget.classList.remove("d-none")

    // Remove name from select
    let select = this.languageSelectTarget.querySelector("select")
    select.removeAttribute("name")

    // Alternative select
    select = this.otherLanguageSelectTarget.querySelector("select")
    select.setAttribute("name", "offer[requested_language]")
    select.setAttribute("required", "required")
  }

  // Cancel requesting an alternative language
  cancelRequestLanguage(event) {
    // Visibility
    this.languageSelectTarget.classList.remove("d-none")
    this.otherLanguageSelectTarget.classList.add("d-none")

    // Add name to old select again
    let select = this.languageSelectTarget.querySelector("select")
    select.setAttribute("name", "offer[language]")
    select.selectedIndex = 0

    // Remove name from alternative select again
    select = this.otherLanguageSelectTarget.querySelector("select")
    select.removeAttribute("name")
    select.removeAttribute("required")
  }

  // Whenever a seach is startet, show loader
  searchStarted(event) {
    this.resultsTarget.innerHTML = ""
    this.resultsTarget.classList.add("bg-loader")
  }

  // Whenever a search is completed, show all offer results and scroll down
  searchCompleted(event) {
    const [data, status, xhr] = event.detail
    this.resultsTarget.innerHTML = JSON.parse(xhr.response).offers
    this.resultsTarget.classList.remove("bg-loader")
    animateScrollTo(this.resultsTarget.offsetTop)
  }

  // Whenever a cart item was added
  addedToCart(event) {
    const [data, status, xhr] = event.detail
    const response = JSON.parse(xhr.response)
    this.cartTarget.insertAdjacentHTML("beforeend", response.bookings)
    this.totalPriceTarget.innerHTML = response.total_price
    this.cartCounterTarget.innerHTML = response.number_of_bookings
    this.checkoutBtnTarget.classList.remove("disabled")
    this.resultsTarget.innerHTML = ""
    this.formParticipantsTarget.value = ""
  }

  // Whenever a cart item was removed
  removedFromCart(event) {
    const [data, status, xhr] = event.detail
    const response = JSON.parse(xhr.response)
    const booking = event.target.closest(".booking")
    this.cartTarget.removeChild(booking)
    this.totalPriceTarget.innerHTML = response.total_price
    this.cartCounterTarget.innerHTML = response.number_of_bookings
    if (response.number_of_bookings == 0)
      this.checkoutBtnTarget.classList.add("disabled")
  }

  // Whenever a user signed-in during checkout, submit changes and redirect to
  // the overview page. If errors occur, print the message
  signedIn(event) {
    const [data, status, xhr] = event.detail
    const response = JSON.parse(xhr.response)
    if (response.state == "success")
      if (response.customer_type === "tour_organizer") {
        this.schoolSelectFieldsTarget.classList.remove("d-none")
        this.schoolSelectFieldsTarget.classList.add("d-flex")
        this.schoolYesBtnTarget.click()

        this.eighthGradeFieldsTarget.classList.remove("d-none")
        this.eighthGradeFieldsTarget.classList.add("d-flex")
        this.eighthGradeYesBtnTarget.click()

        this.loginSignupFormTarget.classList.add("d-none")
        this.nextButtonTarget.classList.remove("d-none")

        this.schoolSelectFieldsTarget.scrollIntoView()
      }
      else if (response.customer_type === "austrian_school") {
        this.eighthGradeFieldsTarget.classList.remove("d-none")
        this.eighthGradeFieldsTarget.classList.add("d-flex")
        this.eighthGradeYesBtnTarget.click()
        this.eighthGradeFieldsTarget.scrollIntoView()

        this.loginSignupFormTarget.classList.add("d-none")
        this.nextButtonTarget.classList.remove("d-none")
      }
      else {
        document.forms["shop-detail-form"].submit()
      }
    else if (response.state == "error") {
      const element = event.target.getElementsByClassName("errors")[0]
      element.innerHTML = response.error
    }
  }

  // Whenever a user signed-up during checkout, submit changes and redirect to
  // the wait-for-confirmation page. If errors occur, print the message
  signedUp(event) {
    const [data, status, xhr] = event.detail
    const response = JSON.parse(xhr.response)
    if (response.state == "success") {
      const form = document.forms["shop-detail-form"]
      let action = new URL(form.action)
      action.searchParams.set("token", response.token)
      form.action = action.toString()

      if (response.customer_type === "austrian_school") {
        this.eighthGradeFieldsTarget.classList.remove("d-none")
        this.eighthGradeFieldsTarget.classList.add("d-flex")
        this.eighthGradeYesBtnTarget.click()
        this.eighthGradeFieldsTarget.scrollIntoView()

        this.loginSignupFormTarget.classList.add("d-none")
        this.nextButtonTarget.classList.remove("d-none")
      }
      else {
        form.submit()
      }
    } else if (response.state == "error") {
      const element = event.target.getElementsByClassName("errors")[0]
      element.innerHTML = response.error
    }
  }

  // Whenever the booking-service selected an existing user
  // Display chosen user-email
  assignedCustomer(event) {
    const [data, status, xhr] = event.detail
    const response = JSON.parse(xhr.response)
    this.chosenCustomerEmailTarget.innerHTML = response.email
    this.chosenCustomerNameTarget.innerHTML = response.name
    this.chooseCustomerTarget.classList.add("d-none")
    this.chosenCustomerTarget.classList.remove("d-none")

    // Decide whether to show school select (for tour_organizer customers)
    if (response.customer_type === "tour_organizer") {
      this.schoolSelectFieldsTarget.classList.remove("d-none")
      this.schoolSelectFieldsTarget.classList.add("d-flex")
      this.schoolYesBtnTarget.click()

      // Allow customer to click next
      this.nextButtonTarget.classList.remove("d-none")
      this.schoolSelectFieldsTarget.scrollIntoView()

      this.eighthGradeFieldsTarget.classList.remove("d-none")
      this.eighthGradeFieldsTarget.classList.add("d-flex")
      this.eighthGradeYesBtnTarget.click()
    } else if (response.customer_type === "austrian_school") {
      this.eighthGradeFieldsTarget.classList.remove("d-none")
      this.eighthGradeFieldsTarget.classList.add("d-flex")
      this.eighthGradeYesBtnTarget.click()
      this.eighthGradeFieldsTarget.scrollIntoView()

      this.nextButtonTarget.classList.remove("d-none")
    } else {
      this.schoolSelectFieldsTarget.classList.remove("d-flex")
      this.schoolSelectFieldsTarget.classList.add("d-none")
      this.hideSchoolSelect()
      this.hideOrganizationName()

      this.eighthGradeFieldsTarget.classList.add("d-none")
      this.eighthGradeFieldsTarget.classList.remove("d-flex")
      this.eighthGradeNoBtnTarget.click()
    }
  }

  // Oposite of assignCustomet: Removed the assigned user
  // Trigger resize so that tabulatorjs works if init hidden
  dismissedCustomer(event) {
    this.chosenCustomerEmailTarget.innerHTML = ""
    this.chosenCustomerNameTarget.innerHTML = ""
    this.chooseCustomerTarget.classList.remove("d-none")
    this.chosenCustomerTarget.classList.add("d-none")
    window.dispatchEvent(new Event("resize"))

    // Hide school select (for tour_organizer customers)
    this.schoolSelectFieldsTarget.classList.remove("d-flex")
    this.schoolSelectFieldsTarget.classList.add("d-none")
    this.schoolNoBtnTarget.click()

    this.organizationNameTarget.removeAttribute("required")
    this.organizationNameTarget.removeAttribute("name")

    this.schoolSelectTarget.removeAttribute("required")
    this.schoolSelectTarget.removeAttribute("name")

    // Hide mobility allowance form
    this.eighthGradeFieldsTarget.classList.remove("d-flex")
    this.eighthGradeFieldsTarget.classList.add("d-none")
    this.eighthGradeNoBtnTarget.click()

    this.eighthGradeTarget.removeAttribute("required")
    this.eighthGradeTarget.removeAttribute("name")
  }

  hideSchoolSelect() {
    this.schoolYesBtnTarget.classList.remove("school--selected")
    this.schoolNoBtnTarget.classList.remove("school--selected")
    this.schoolNoBtnTarget.classList.add("school--selected")

    // Hide school select (for tour_organizer customers)
    this.schoolSelectContainerTarget.classList.remove("d-flex")
    this.schoolSelectContainerTarget.classList.add("d-none")
    this.schoolSelectTarget.removeAttribute("required")
    this.schoolSelectTarget.removeAttribute("name")
  }

  hideEighthGrade() {
    this.eighthGradeFieldsTarget.classList.remove("d-flex")
    this.eighthGradeFieldsTarget.classList.add("d-none")
    this.eighthGradeTarget.removeAttribute("required")
    this.eighthGradeTarget.removeAttribute("name")
  }

  showEighthGrade() {
    this.eighthGradeFieldsTarget.classList.add("d-flex")
    this.eighthGradeFieldsTarget.classList.remove("d-none")
    this.showEighthGradeField()
  }

  showSchoolSelect() {
    this.schoolYesBtnTarget.classList.remove("school--selected")
    this.schoolNoBtnTarget.classList.remove("school--selected")
    this.schoolYesBtnTarget.classList.add("school--selected")

    // Hide school select (for tour_organizer customers)
    this.schoolSelectContainerTarget.classList.remove("d-none")
    this.schoolSelectContainerTarget.classList.add("d-flex")

    this.schoolSelectTarget.setAttribute("required", "required")
    this.schoolSelectTarget.setAttribute(
      "name",
      "bookings[booked_for_school_number]"
    )
  }

  // Whenever the booking-service created a new customer
  // If it succeeded, display chosen user-email, and switch to other tab
  // If an errors occurs, print the message
  createdCustomer(event) {
    const [data, status, xhr] = event.detail
    const response = JSON.parse(xhr.response)
    if (response.state == "success") {
      this.chosenCustomerEmailTarget.innerHTML = response.email
      this.chosenCustomerNameTarget.innerHTML = response.name
      this.element.getElementsByClassName("select-customer-tab")[0].click()
      this.chooseCustomerTarget.classList.add("d-none")
      this.chosenCustomerTarget.classList.remove("d-none")
      const form = document.forms["shop-detail-form"]

      if (response.customer_type === "austrian_school") {
        this.eighthGradeFieldsTarget.classList.remove("d-none")
        this.eighthGradeFieldsTarget.classList.add("d-flex")
        this.eighthGradeYesBtnTarget.click()
        this.eighthGradeFieldsTarget.scrollIntoView()

        this.nextButtonTarget.classList.remove("d-none")
      }
    } else if (response.state == "error") {
      const element = event.target.getElementsByClassName("errors")[0]
      element.innerHTML = response.error
    }
  }

  // Travel agencies
  showOrganizationName(event) {
    event.preventDefault()
    this.schoolYesBtnTarget.classList.remove("school--selected")
    this.schoolNoBtnTarget.classList.remove("school--selected")
    event.target.classList.add("school--selected")
    this.organizationNameContainerTarget.classList.remove("d-none")
    this.organizationNameTarget.setAttribute("required", "required")
    this.organizationNameTarget.setAttribute(
      "name",
      "bookings[booked_for_organization_name]"
    )
  }

  // Travel agencies
  hideOrganizationName(event) {
    event.preventDefault()
    this.schoolYesBtnTarget.classList.remove("school--selected")
    this.schoolNoBtnTarget.classList.remove("school--selected")
    this.schoolYesBtnTarget.classList.add("school--selected")
    this.organizationNameContainerTarget.classList.add("d-none")
    this.organizationNameTarget.removeAttribute("required")
    this.organizationNameTarget.removeAttribute("name")
  }

  // Travel agencies
  showEighthGradeField(event) {
    event.preventDefault()
    this.eighthGradeYesBtnTarget.classList.remove("eighthGrade--selected")
    this.eighthGradeNoBtnTarget.classList.remove("eighthGrade--selected")
    event.target.classList.add("eighthGrade--selected")
    this.eighthGradeContainerTarget.classList.remove("d-none")
    this.eighthGradeTarget.setAttribute("required", "required")
    this.eighthGradeTarget.setAttribute(
      "name",
      "bookings[number_of_8th_grades]"
    )
  }

  // Travel agencies
  hideEighthGradeField(event) {
    event.preventDefault()
    this.eighthGradeYesBtnTarget.classList.remove("eighthGrade--selected")
    this.eighthGradeNoBtnTarget.classList.remove("eighthGrade--selected")
    event.target.classList.add("eighthGrade--selected")
    this.eighthGradeContainerTarget.classList.add("d-none")
    this.eighthGradeTarget.removeAttribute("required")
    this.eighthGradeTarget.removeAttribute("name")
  }

  // Submits the details form
  submitDetails(event) {
    this.saveDetailsBtnTarget.click()
  }
}
