import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
  }

  depositsTableController() {
    return Stimulus.getControllerForElementAndIdentifier(document.querySelector("[data-controller='table']"), "table")
  }

  reset() {
    this.resetInputsInElement('[data-controller=table]')
    this.depositsTableController().filter()
  }

  exportCsv() {
    this.depositsTableController().export('/de/deposits/data', 'csv')
  }

  exportXls() {
    this.depositsTableController().export('/de/deposits/data', 'xls')
  }

  resetInputsInElement(selector) {
    document.querySelector("[data-action='table#resetDatepicker']").click()

    const container = document.querySelector(selector);

    if (!container) {
      console.error(`Element with selector "${selector}" not found.`);
      return;
    }

    // Reset regular inputs
    const inputs = container.querySelectorAll('input');
    inputs.forEach(input => {
      if (input.type === 'checkbox' || input.type === 'radio') {
        input.checked = false;
      } else {
        input.value = '';
      }
    });

    // Reset select elements
    const selects = container.querySelectorAll('select');
    selects.forEach(select => {
      select.selectedIndex = 0;
    });

    // Reset textarea elements
    const textareas = container.querySelectorAll('textarea');
    textareas.forEach(textarea => {
      textarea.value = '';
    });
  }

}
